import {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';

import Header from '../components/Header';
import NewCMS from 'components/NewCMS';
import {useSelector} from 'react-redux';

const Dashboard = () => {
    let navigate = useNavigate();
    const auth = useSelector(state => state.auth);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        isAuthenticate();
    }, []);

    const isAuthenticate = () => {
        const cookie = Cookies.get('kiwi_auth_token');
        if (!cookie) {
            setLoading(false);
            navigate('/auth');
        }
        setLoading(false);
    };

    return loading ? (
        <div>
            <h1>Loading</h1>
        </div>
    ) : (
        <>
            {auth?.profile !== 'cms' && <Header />}
            <NewCMS profile={auth?.profile !== 'cms'} />
            <Outlet />
        </>
    );
};

export default Dashboard;
