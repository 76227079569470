export default function NewCMS({profile}) {
    const {REACT_APP_NEW_CMS} = process.env;

    return (
        <div className="flex items-center justify-center py-4  bg-dark-kiwi-v2">
            <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
                <p className="text-sm leading-6 text-white">
                    <strong className="font-semibold">New CMS now</strong>
                    <svg
                        viewBox="0 0 2 2"
                        aria-hidden="true"
                        className="mx-2 inline h-0.5 w-0.5 fill-current">
                        <circle r={1} cx={1} cy={1} />
                    </svg>
                    The version of the new CMS is now available.
                </p>
                <div className="flex gap-4">
                    {!profile && (
                        <button
                            className="rounded-full bg-white px-5 py-1 text-sm font-semibold text-gray-500 cursor-pointer transition-all duration-200 z-[100] shadow-xl"
                            onMouseEnter={e => {
                                const button = e.target;
                                // Primero hacemos el botón fixed y guardamos su posición original
                                if (!button.style.position) {
                                    const rect = button.getBoundingClientRect();
                                    button.style.position = 'fixed';
                                    button.style.left = `${rect.left}px`;
                                    button.style.top = `${rect.top}px`;
                                }

                                // Luego lo movemos a una posición aleatoria
                                const padding = 20;
                                const maxX =
                                    window.innerWidth -
                                    button.offsetWidth -
                                    padding;
                                const maxY =
                                    window.innerHeight -
                                    button.offsetHeight -
                                    padding;
                                const randomX =
                                    Math.floor(
                                        Math.random() * (maxX - padding),
                                    ) + padding;
                                const randomY =
                                    Math.floor(
                                        Math.random() * (maxY - padding),
                                    ) + padding;
                                button.style.left = `${randomX}px`;
                                button.style.top = `${randomY}px`;
                            }}>
                            Use current CMS
                        </button>
                    )}
                    <a
                        href={REACT_APP_NEW_CMS ?? '#'}
                        target="_blank"
                        className="rounded-full bg-white px-5 py-1 text-sm font-semibold text-dark-kiwi-v2 shadow-sm cursor-pointer">
                        go to new CMS <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div>
        </div>
    );
}
